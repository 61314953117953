export const coverageEffectiveControl = {
    label: 'Requested Effective Date',
    prop: 'coverage_effective_date',
    type: 'dropdown',
    validators: ['required'],
};

// todo: need generic text from product
export const eligibilityTypes = {
    medicaid:
        "Sweet, looks like you may qualify for some financial assistance, but there\u2019s a few more steps in the process. Call Friday\u2019s insurance experts at <a class='t-data t-link' href='tel: 844-451-4444'>844-451-4444</a> to get more specifics and enroll with your subsidy. Or continue to shop plans without the subsidy amount reflected.",
    subsidy:
        "Sweet, looks like you may qualify for some financial assistance, but there\u2019s a few more steps in the process. Call Friday\u2019s insurance experts at <a class='t-data t-link' href='tel: 844-451-4444'>844-451-4444</a> to get more specifics and enroll with your subsidy. Or continue to shop plans without the subsidy amount reflected.",
    ineligible:
        'Looks like you do not qualify for financial assistance. If you have questions, call Friday\u2019s insurance experts at 844-451-4444.',
};

export const subsidyControls = [
    {
        label: 'Household Size',
        prop: 'household_size',
        type: 'text',
        mask: 'number',
        maxLength: 1,
        description: 'EX. 3',
        errors: {
            max: 'Please provide a household size less than 7',
        },
        validators: [
            'required',
            {
                name: 'max',
                maxNum: 7,
            },
        ],
    },
    {
        label: 'Are you an Alaskan or Native American? ',
        prop: 'native_indian_alaskan',
        type: 'toggle',
        description:
            "If you are a member of a federally recognized Indian tribe or Alaska Native tribe entity, you qualify to enroll in unique, low cost health insurance products that are only available to American Indians or Alaska Natives. Friday Health Plans offers these special plans through Connect for Health Colorado, the exchange marketplace. Call Connect for Health Colorado, or you may contact our Sales Center at <a class='t-data t-link' href='tel: 844-451-4444'>844-451-4444</a>.",
        options: [
            {
                value: true,
                label: 'Yes',
            },
            {
                value: false,
                label: 'No',
            },
        ],
        mask: 'number',
        validators: ['required'],
    },
    {
        label: 'Household Income',
        prop: 'annual_income',
        type: 'text',
        mask: 'currency',
        description: 'EX. 20,000',
        validators: ['required'],
    },
];

export const defaultSubsidyConfig = {
    button: {
        content: 'Calculate Subsidy',
    },
    eligibilityTypes: eligibilityTypes,
    controls: subsidyControls,
    title: 'Would you like to find out if you can get help paying for coverage?',
    subtitle:
        'You may qualify for additional financial help based on your household size and income, or if you belong to a federally recognized American Indian tribe.',
};

export const hideMemberOptionsMap = {
    subscriber: {
        child_only: true,
        children: true,
    },
    spouse: {
        child_only: true,
        children: true,
    },
    family: {
        child_only: true,
        children: true,
    },
    parent: {
        child_only: true,
        children: true,
    },
    chid_only: {
        subscriber: true,
        spouse: true,
        family: true,
        parent: true,
    },
    children: {
        subscriber: true,
        spouse: true,
        family: true,
        parent: true,
    },
};

export const defaultMemberCoverageOption = 'subscriber';

export const friendlyDateMap = {
    0: 'January 1',
    1: 'February 1',
    2: 'March 1',
    3: 'April 1',
    4: 'May 1',
    5: 'June 1',
    6: 'July 1',
    7: 'August 1',
    8: 'September 1',
    9: 'October 1',
    10: 'November 1',
    11: 'December 1',
};

export const marketSegmentPropName = 'market_segment';
