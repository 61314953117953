import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs';
import 'rxjs';
import 'rxjs';
import 'rxjs';
import 'rxjs';
import 'rxjs';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';

/**
 * Mock backend for testing dashboard functionality
 */
@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    constructor(private configService: ConfigService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.configService && this.configService.configs) {
            const baseUrlRequest = request.clone({ url: this.configService.getPageConfig<any>('global').DEFAULT.baseUrl + request.url });
            return next.handle(baseUrlRequest);
        }
        return next.handle(request);
    }
}

export const BASE_URL_PROVIDER = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: BaseUrlInterceptor,
    multi: true,
};
