import { WorkflowType } from '../models/shared/Workflow.model';

export class CXCaptureConfig {
    source_system_name: string = '';
    endpoint: string = '';
    mapping?: any;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

export class GAConfig {
    mapping?: any;

    // use either gtag or ga based on the requested google analytics library... provide the id
    gtag?: string;
    ga?: string;
}

export enum validCXEvents {
    'checkout' = 'checkout',
    'plan-details_viewed' = 'plan-details_viewed',
    'plan-details_link_clicked' = 'plan-details_link_clicked',
    'plans-list_link_clicked' = 'plans-list_link_clicked',
    'plans-list_viewed' = 'plans-list_viewed',
    'plan_added-to-cart' = 'plan_added-to-cart',
    'plan_removed-from-cart' = 'plan_removed-from-cart',
    'contact-us_viewed' = 'contact-us_viewed',
    plans_compared = 'plans_compared',
    application_printed = 'application_printed',
    pcp_selection = 'pcp_selection',
    pcp_remove_selection = 'pcp_remove_selection',
    virtualPageView = 'virtualPageView',
    page_view = 'page_view',
    qle_selected = 'qle_selected',
    user_login = 'user_login',
    quote_started = 'quote_started',
    application_started = 'application_started',
    application_continued = 'application_continued',
    application_submitted = 'application_submitted',
    application_declined = 'application_declined',
    application_exited = 'application_exited',
    application_downloaded = 'application_downloaded',
    registration_submit = 'registration_submit',
    navigate_to_workbench = 'navigate_to_workbench',
    year_to_year_active_renewal = 'year_to_year_active_renewal',
    year_to_year_passive_renewal = 'year_to_year_passive_renewal',
    contact_us_flyout_details = 'contact_us_flyout_details',
    footer_clicked = 'footer_clicked',
    email_clicked = 'email_clicked',
    phone_clicked = 'phone_clicked',
}

export enum validGAEvents {
    'checkout' = 'checkout',
    'plan-details_viewed' = 'productClickDetailsViewed',
    'plan-details_link_clicked' = 'productLinkClicked',
    'plans-list_link_clicked' = 'productLinkClicked',
    'plans-list_viewed' = 'productImpressions',
    'plan_added-to-cart' = 'addToCart',
    'plan_removed-from-cart' = 'removeFromCart',
    'contact-us_viewed' = 'contact-us_viewed',
    plans_compared = 'plans_compared',
    application_printed = 'application_printed',
    pcp_selection = 'pcp_selection',
    pcp_remove_selection = 'pcp_remove-selection',
    virtualPageView = 'virtualPageView',
    page_view = 'page_view',
    qle_selected = 'qle_selected',
    user_login = 'user_login',
    quote_started = 'quote_started',
    application_started = 'application_started',
    application_continued = 'application_continued',
    application_submitted = 'application_submitted',
    application_declined = 'application_declined',
    application_exited = 'application_exited',
    application_downloaded = 'application_downloaded',
    OnExchangeRequest = 'OnExchangeRequest',
    registration_submit = 'registration_submit',
    button_clicked = 'button_clicked',
    navigate_to_workbench = 'navigate_to_workbench',
    year_to_year_active_renewal = 'year_to_year_active_renewal',
    year_to_year_passive_renewal = 'year_to_year_passive_renewal',
    contact_us_flyout_details = 'contact_us_flyout_details',
    footer_clicked = 'footer_clicked',
    email_clicked = 'email_clicked',
    phone_clicked = 'phone_clicked',
}

export class AnalyticsContext {
    CXKey?: validCXEvents;
    GAKey?: validGAEvents;
}

// Prefixes should not contain an additional underscore, eg: 'mabp' instead of 'ma_bp'
// This is to ensure only two underscores in a pattern of `product_object_action`
// https://docs.zipari.io/docs/how-do-you-name-your-event-keys
export const ProductPrefixes = {
    zshop: 'sp_',
    scout: 'ps_',
    'provider-portal': 'pp_',
    'member-portal': 'mp_',
    'broker-portal': 'bp_',
    'zshop-medicare': 'mashp_',
    'broker-portal-medicare': 'mabp_',
};

export class workflowContext {
    workflow_id?: number;
    title: string;
    key: string;
    webuser_id?: number;
    web_session_id?: string;
    type?: WorkflowType;
}

export const WorkflowPrefixes = {
    [WorkflowType.individual]: 'app-',
    [WorkflowType.shp_individual_quote]: 'quote-',
    [WorkflowType.bp_individual_quote]: 'quote-',
};
