import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Event } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, RouteConfigLoadEnd, Router } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { applyIconsFromConfig, getValue, applyTheme, applyFonts } from '@zipari/web-utils';
import { filter, tap, first, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { GlobalConfig, KeepaliveConfig, TimeoutConfig } from './app.constants';
import { AuthService, ConfigService, IdleService } from './shared/services';
import { AnalyticsService } from './shared/services/analytics.service';
import { BrowserSupportService } from 'src/app/shared/services/browser-support.service';

const checkForNonAppUrl = (event: NavigationEnd): boolean => event.urlAfterRedirects.split('?')[0] === '/';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public isAppLoading = true;
    public lastPing: Date;
    public typeface;

    constructor(
        public authService: AuthService,
        private keepalive: Keepalive,
        public idleService: IdleService,
        public configService: ConfigService,
        public title: Title,
        private router: Router,
        private http: HttpClient,
        private analyticsService: AnalyticsService,
        private browserSupportService: BrowserSupportService
    ) {
        const globalConfig: GlobalConfig = this.configService.getPageConfig<GlobalConfig>('global');

        this.setupTimeouts(globalConfig.timeout);
        this.setTitle(globalConfig.title);
        this.setupRouterCheckForAuth(globalConfig);

        if (globalConfig?.analytics?.captureGlobalClickEvents) {
            this.analyticsService.initGlobalClickEvents();
        }

        if (globalConfig.browserSupport?.showModal) {
            this.browserSupportService.openBrowserSupportModal();
        }
    }

    ngOnInit() {
        this.changeThemeFromNewConfig();
    }

    setUserState(routeParameters: Record<string, unknown>) {
        // TODO: Confirm this works for Broker.
        // The old code only did this for shopping
        // Also, this gets the state even if logged in already.
        // Confirm that this is ok if already logged in

        /* get workflow id from the router */
        const workflowId = routeParameters['id']; // +plus sign converts string to number
        const isApplication: boolean = window.location.toString().includes('individual');
        const parentSegment: string = window.location.pathname.split('/')[1];
        const applicationsURL = workflowId ? `${parentSegment}/individual/${workflowId}` : `${parentSegment}/applications/`;

        /* Set a replay_url in order to redirect consumer to the workflow after register, login */
        /* if there is no workflow id, user will be redirect to the applications  */
        const payload = {
            post_sso_action: 'replay_url',
            replay_url: isApplication ? applicationsURL : this.router.url,
        };

        this.authService.postUserState(payload).subscribe(state => console.log(state));
    }

    setTitle(titleConfig: string): void {
        if (titleConfig) {
            this.title.setTitle(titleConfig);
        }
    }

    setupTimeouts(timeoutConfig: TimeoutConfig): void {
        const config: TimeoutConfig = new TimeoutConfig(timeoutConfig);

        this.idleService.setupIdle(config.idle);
        this.setupKeepalive(config.keepalive);
    }

    /** documentation can be found @ https://hackedbychinese.github.io/ng2-idle/ */
    setupKeepalive(keepaliveConfig: KeepaliveConfig): void {
        if (keepaliveConfig.enabled) {
            this.keepalive.onPing.subscribe(() => {
                this.lastPing = new Date();

                this.http.get(keepaliveConfig.endpoint).subscribe(
                    () => {},
                    (err: Error) => {
                        if (keepaliveConfig.error.link) {
                            window.location.assign(keepaliveConfig.error.link);
                        } else if (keepaliveConfig.error.route) {
                            this.router.navigate([keepaliveConfig.error.route]);
                        }
                    }
                );
            });

            // Sets the ping keepaliveInterval to 15 seconds
            this.keepalive.interval(keepaliveConfig.interval);
        }
    }

    setupRouterCheckForAuth(globalConfig): void {
        // Subscribe to router events to show loading indicator when app modules (e.g. broker, shopping portals) are
        // compiling
        // navigate to app route when url redirect fails
        this.router.events
            .pipe(
                tap((event: Event) => {
                    // check for a default route
                    let defaultRoute = getValue(globalConfig, 'defaultRoute') || getValue(environment, 'configs.defaultRoute');

                    // if no route is specified and config has an alternate default route, then route there
                    if (defaultRoute && event['url'] === '/') {
                        this.router.navigate([defaultRoute]);
                    }

                    if (!defaultRoute) {
                        defaultRoute = 'applications';
                    }

                    if (event['urlAfterRedirects'] === '/shopping/applications') {
                        if (defaultRoute !== 'applications') {
                            this.router.navigate([this.configService.appRoute, defaultRoute], {
                                queryParamsHandling: 'merge',
                            });
                        }
                    }

                    if (event instanceof NavigationEnd && checkForNonAppUrl(event)) {
                        this.router.navigate([this.configService.appRoute], {
                            queryParamsHandling: 'merge',
                        });
                    }
                    if (
                        this.authService.loggedInUser &&
                        this.authService.loggedInUser.app_user_data &&
                        this.idleService.enabled &&
                        !this.idleService.isIdling
                    ) {
                        this.idleService.resetTimeout();
                    } else if (this.idleService.isIdling) {
                        this.idleService.stopTimeout();
                    }
                }),
                filter((event: Event) => event instanceof RouteConfigLoadEnd || event instanceof NavigationEnd)
            )
            .subscribe(() => {
                this.isAppLoading = false;
            });
        this.router.events
            .pipe(
                filter((e) => e instanceof ActivationEnd && Object.keys(e.snapshot.params).length > 0),
                map((e) => (e instanceof ActivationEnd ? e.snapshot.params : {}))
            )
            .subscribe((params) => {
                this.setUserState(params);
            });
    }

    applyFonts(font_book) {
        applyFonts(font_book);
    }

    /** Tracks config changes and updates theme based on new theme in configs.
     * Also sets the app config in the auth service as well.
     * */
    private changeThemeFromNewConfig() {
        this.configService.configUpdated.pipe(first()).subscribe((config) => {
            this.authService.setAppConfig(config);

            if (config?.theme) {
                applyTheme(config.theme);
            }

            if (config?.icon_library) {
                applyIconsFromConfig(config.icon_library);
            }

            if (config?.font_book?.fonts) {
                this.applyFonts(config.font_book.fonts);
            }
        });
    }
}
