<modal
    [condition]="true"
    [config]="{
        type: 'popup',
        header: {
            title: config.header
        }
    }"
    (cancel)="cancel.next(null)"
>
    <ng-container body>
        <ng-container *ngIf="config.HTMLBody; else modalBody">
            <div [innerHtml]="config.HTMLBody"></div>
        </ng-container>

        <ng-template #modalBody>
            <p>{{ config.body | stringBuilder: context }}</p>
        </ng-template>
    </ng-container>

    <ng-container footer>
        <div class="u-flex u-alignCenter u-justifyBetween u-maxX" [ngClass]="{ 'u-justifyEnd': !config.cancel }">
            <zip-button
                *ngIf="config.cancel"
                [config]="{
                    level: 'text',
                    content: 'Cancel'
                }"
                (zipButtonClicked)="cancel.next(null)"
            >
            </zip-button>

            <zip-button
                *ngIf="config.confirm"
                [config]="{
                    level: 'high',
                    content: config.confirm
                }"
                (zipButtonClicked)="confirm.next(null)"
            >
            </zip-button>
        </div>
    </ng-container>
</modal>
