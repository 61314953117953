import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getValue, isEmptyObj, VALID_ROLES } from '@zipari/web-utils';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { LoggerService } from './logger.service';
import { finalize } from 'rxjs/operators';
import { User } from './auth.service.constants';

@Injectable()
export class AuthService {
    public replayPath: string;
    public appConfig: any;
    // public userRole = 'Broker';
    public user: any;
    public actualUser: any;
    public impersonatedUser: any;
    private _appUserData = new BehaviorSubject(null);
    private _state_id;
    private readonly forgotPasswordUrl = 'api/user/forgot_password/';
    private readonly resetPasswordUrl = 'user/reset_password/';
    private readonly changePasswordUrl = 'user/change-password/';
    private readonly defaultLogoutUrl = 'user/logout/';

    constructor(
        private http: HttpClient,
        private loggerService: LoggerService,
        private configService: ConfigService
    ) {}

    public get appUserData(): Observable<any> {
        return this._appUserData.asObservable();
    }

    public get loggedInUser(): User {
        return this.impersonatedUser ? this.impersonatedUser : this.actualUser;
    }

    public get userRole() {
        if (!this.loggedInUser || this.loggedInUser?.roles?.length === 0) {
            // TODO let's change this to be a constant, not a string
            return 'Anonymous';
        }

        // TODO This has gotten over complicated.. cant this just be the this.user?
        if (this.loggedInUser.app_user_data?.data?.active_role) {
            return this.loggedInUser.app_user_data.data.active_role;
        }

        const validRoles = this.loggedInUser.roles.filter((role) => VALID_ROLES.includes(role.name));

        if (validRoles.length < 1) {
            this.loggerService.warn('No Valid Roles for Logged in User');
        }

        return validRoles[0].name;
    }

    public get roleCount() {
        return this.loggedInUser ? this.loggedInUser.roles.filter((role) => VALID_ROLES.includes(role.name)).length : 0;
    }

    public get isBroker() {
        return !!getValue(this.user, 'broker_id');
    }

    get externalLoginUrl(): string {
        const { externalLogin } = this.appConfig.global;

        // safe check because environment variables currently can't be changed to empty string
        return externalLogin && externalLogin.indexOf('http') >= 0 ? externalLogin : '';
    }

    get externalRegisterUrl(): string {
        const { externalRegister } = this.appConfig.global;

        // safe check because environment variables currently can't be changed to empty string
        return externalRegister && externalRegister.indexOf('http') >= 0 ? externalRegister : '';
    }

    get state_id() {
        return this._state_id || '';
    }

    set state_id(newStateId) {
        this._state_id = newStateId;
    }

    public postUserState(payload): Observable<any> {
        return this.http.post('api/user/state/', payload).pipe(tap((resp) => (this._state_id = resp.state_id)));
    }

    login(payload: { username: string; password: string }): Observable<any> {
        let { loginEndpoint = '/login/' } = this.appConfig.login.loginEndpoint;
        const { setActionForStateQP } = this.appConfig.global;

        if (loginEndpoint && this.state_id && setActionForStateQP) {
            loginEndpoint = `${loginEndpoint}${setActionForStateQP}state=${this.state_id}`;
        } else if (loginEndpoint && this.state_id) {
            const loginHasQP: boolean = loginEndpoint.includes('&');
            loginHasQP
                ? (loginEndpoint = `${loginEndpoint}&state=${this.state_id}`)
                : (loginEndpoint = `${loginEndpoint}?state=${this.state_id}`);
        }

        return this.http.post<any>(loginEndpoint, payload).pipe(
            tap((response) => {
                this._appUserData.next(response);
            })
        );
    }

    logout() {
        return this.http.post<any>('api/user/logout/', {});
    }

    register(payload: any): Observable<any> {
        let baseUrl: string = '/user/register/';
        const registerEndpoint = this.state_id ? `${baseUrl}?state=${this.state_id}` : baseUrl;

        return this.http.post(registerEndpoint, payload);
    }

    getUser(dataSource): Promise<any> {
        return dataSource.userDataSource === 'init_data' ? this.getUserFromInitData() : this.getUserFromApi();
    }

    getUserFromApi() {
        return new Promise((resolve, reject) => {
            this.http
                .get<any>('api/user/')
                .toPromise()
                .then((data) => {
                    this._appUserData.next(data);
                    resolve(data);
                })
                .catch((data) => {
                    this.actualUser = null;
                    this.user = {};
                    if (data.status === 403 || data.status === 401) {
                        return resolve(null);
                    }
                    reject(data);
                });
        });
    }

    setAppConfig(config) {
        this.appConfig = config;
    }

    setLoggedInUser(data, config?) {
        if (isEmptyObj(data)) {
            return;
        }

        if (!config || (config && config.userDataSource !== 'init_data')) {
            // Anon hacked together need to rework it to make it cleaner
            if (data) {
                this.actualUser = {
                    app_user_data: data,
                    roles: data.roles,
                };

                this._appUserData.next(data);
            }
            this.user = data;
        }
    }

    getUserFromInitData() {
        const promise = this.http.get<any>('init_data').toPromise();
        const enrollmentUserPromise = this.http.get<any>('/api/user/').toPromise();

        enrollmentUserPromise.then((enrollUserData) => {
            this.user = enrollUserData.impersonated_user ? enrollUserData.impersonated_user : enrollUserData;
        });
        promise.then((data) => {
            this._appUserData.next(data);
            this.actualUser = data.USER_INFO;
            this.impersonatedUser = data.USER_INFO.impersonated_user;
        });

        return Promise.all([enrollmentUserPromise, promise]).then();
    }

    setUserRole(role) {
        const data = { data: { active_role: role } };

        return new Promise((resolve) => {
            this.http.put(`api/user/`, data).subscribe(() => {
                this.getUserFromApi().then(() => {
                    resolve(null);
                });
            });
        });
    }

    /**
     * set user data
     * @param data user.data json-blob
     */
    setUserData(data: any) {
        return this.http.put(`api/user/`, { data }).subscribe((userData) => {
            this._appUserData.next(userData);
        });
    }

    sendForgotPasswordEmail(payload): Observable<any> {
        const apiEndpoint = payload.endpoint || this.forgotPasswordUrl;
        const { endpoint, next, username, ...rest} = payload;
        const postPayload = apiEndpoint === this.forgotPasswordUrl ? rest : payload;

        return this.http.post<string>(apiEndpoint, postPayload);
        // TODO: want to pass `next` URL in JSON, but backend refusing ATM
        // return this.http.post<string>(this.forgotPasswordUrl, payload);
    }

    resetPassword(payload: { user_name: string; password: string; confirm_password: string; token: string }): Observable<any> {
        return this.http.post<string>(this.resetPasswordUrl, payload);
    }

    changePassword(payload: { current_password: string; password: string; confirm_password: string }): Observable<any> {
        const formattedPayload = {
            success_url: window.location.href,
            current_password: payload.current_password,
            new_password: payload.password,
            confirm_new_password: payload.confirm_password,
        };

        return this.http.post<string>(this.changePasswordUrl, formattedPayload);
    }

    updateUserData(data: any): Observable<any> {
        return this.http.put('api/user/', { data }).pipe(
            tap((userData) => {
                this.setLoggedInUser(userData);
            })
        );
    }

    handleExternalRegisterOrLogin() {
        if (this.externalRegisterUrl) {
            this.handleExternalRegister();
        } else {
            this.handleExternalLogin();
        }
    }

    handleExternalRegister() {
        let externalRegister: string = this.externalRegisterUrl;

        if (externalRegister && this.state_id) {
            const externalLoginHasQP: boolean = externalRegister.includes('&');

            externalLoginHasQP
                ? (externalRegister = `${externalRegister}&state=${this.state_id}`)
                : (externalRegister = `${externalRegister}?state=${this.state_id}`);
        }

        if (externalRegister) window.location.assign(externalRegister);
    }

    handleExternalLogin(): boolean {
        let externalLogin: string = this.externalLoginUrl;
        const setActionForStateQP: string = this.appConfig.global.setActionForStateQP;
        const logsEnabled: boolean = !!this.appConfig?.global?.logsEnabled;

        if (logsEnabled) {
            this.loggerService.logGroup([externalLogin, setActionForStateQP, this.state_id], 'handleExternalLogin');
        }
        
        if (externalLogin && this.state_id && setActionForStateQP) {
            externalLogin = `${externalLogin}${setActionForStateQP}state=${this.state_id}`;
            window.location.assign(externalLogin);
        } else if (externalLogin && this.state_id) {
            const externalLoginHasQP: boolean = externalLogin.includes('&');
            externalLoginHasQP
                ? (externalLogin = `${externalLogin}&state=${this.state_id}`)
                : (externalLogin = `${externalLogin}?state=${this.state_id}`);

            window.location.assign(externalLogin);
        } else if (externalLogin) {
            window.location.assign(externalLogin);
        }

        return !!externalLogin;
    }

    handleLogout(next = '') {
        const { logoutUrl = this.defaultLogoutUrl, doLogoutPost, postRevokeUrl, externalLogout } = this.appConfig.global;
        const nextUrl = next || `${window.location.origin}/${this.configService.appRoute}/login`;

        if (doLogoutPost) {
            this.http
                .post(logoutUrl, {})
                .pipe(
                    finalize(() => {
                        window.location.assign(postRevokeUrl);
                    })
                )
                .subscribe();
        } else if (externalLogout) {
            window.location.assign(`/logout?next=${externalLogout}`);
        } else {
            window.location.assign(`/logout?next=${nextUrl}`);
        }
    }
}
