import { OverlayModule } from '@angular/cdk/overlay';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { ModalModule, ButtonModule, IconModule } from '@zipari/design-system';

import {
    AddressPipe,
    BuildQueryPipe,
    DoesListHaveValuesPipe,
    EqualityFilterPipe,
    FullNamePipe,
    GetValuePipe,
    MaskPipe,
    PhoneNumberPipe,
    PipesModule,
    ReadMorePipe,
    SafeNumberPipe,
    StringBuilderPipe,
} from '@zipari/web-utils';
import { CONFIG } from '../config';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalWrapperComponent } from './shared/components/confirmation-modal-wrapper/confirmation-modal-wrapper.component';

import { CoreModule } from './shared/core.module';
import { TimeoutModalModule } from './shared/modules/timeout-modal/timeout-modal.module';
import { PipeModule } from './shared/pipes/pipe.module';
import { AuthService, ConfigService } from './shared/services';
import { AnalyticsService } from './shared/services/analytics.service';
import { STATE_COHORT_PROVIDER } from './shared/services/state-cohort.interceptor';
import { SitemapComponent } from './sitemap.component';

@NgModule({
    declarations: [AppComponent, ConfirmationModalComponent, ConfirmationModalWrapperComponent, SitemapComponent],
    imports: [
        AppRoutingModule,
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        OverlayModule,
        PipeModule,
        CoreModule.forRoot(),
        PipesModule,
        NgIdleKeepaliveModule.forRoot(),
        TimeoutModalModule,
        ModalModule,
        ButtonModule,
        IconModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: resolveConfigUserData,
            deps: [ConfigService, AuthService],
            multi: true,
        },
        // todo: commenting out because it is swallowing errors
        // {
        //     provide: ErrorHandler,
        //     useClass: SentryErrorHandlerService,
        // },
        Title,
        AnalyticsService,
        StringBuilderPipe,
        AddressPipe,
        GetValuePipe,
        EqualityFilterPipe,
        BuildQueryPipe,
        DoesListHaveValuesPipe,
        FullNamePipe,
        PhoneNumberPipe,
        MaskPipe,
        ReadMorePipe,
        SafeNumberPipe,
        CurrencyPipe,
        DatePipe,
        STATE_COHORT_PROVIDER,
        CurrencyPipe,
        DatePipe,
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function resolveConfigUserData(configService: ConfigService, authService: AuthService) {
    let configPromise;
    let useLocalConfig = false;

    if (isDevMode() && !window['CONFIG']) {
        window['CONFIG'] = CONFIG;
    }

    if (typeof script_useLocalConfig !== 'undefined') {
        // gets called if local development using command line configuration for useLocalConfig
        useLocalConfig = script_useLocalConfig;
    }

    if (useLocalConfig) {
        configPromise = configService.initializeLocalConfig(environment['app']);
    } else if (!useLocalConfig && overrideDbFromLocalConfig) {
        configPromise = configService.initializeDBConfigWithLocalOverride(environment['app'], {
            setConfig: true,
            returnFullConfig: true,
        });
    } else {
        configPromise = configService.initializeConfig();
    }

    return () => {
        // Ensures logged in user set only after config and user data fetched
        return configPromise.then(config => {
            authService.setAppConfig(config.APP);

            let user = {};

            try {
                user = JSON.parse(config.USER);
            } catch (err) {
                if (isDevMode()) {
                    console.error(err);
                }
            }

            if (!environment['configs']['ignoreAuth']) {
                authService.setLoggedInUser(user, environment['configs']);
            }
        });
    };
}
