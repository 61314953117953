export enum ApplicationActions {
    route = 'route',
    link = 'link',
    contact = 'contact',
    'share-live-application' = 'share-live-application',
    'share-application-pdf' = 'share-application-pdf',
    'download-application' = 'download-application',
    'delete-application' = 'delete-application',
    'share-application' = 'share-application',
    'change-coverage' = 'change-coverage',
    'continue-application' = 'continue-application',
    'document-upload' = 'document-upload',
}

export const memberCardAttributesFromEnrollment = [
    {
        label: 'Name',
        value: '${first_name} ${last_name} ${suffix}',
    },
    {
        label: 'Gender',
        value: '${gender}',
    },
    {
        label: 'Date of Birth',
        value: '${date_of_birth}',
    },
    {
        label: 'Tobacco Use',
        value: '${tobacco_user}',
        format: 'YES_NO',
        noValueMessage: 'HIDE',
    },
    {
        label: 'Address',
        value: '${address.street_name_1}<br>${address.city}, ${address.state} ${address.zipcode}',
    },
    {
        label: 'Mailing Address',
        value: '${mailing_address.street_name_1}<br>${mailing_address.city}, ${mailing_address.state} ${mailing_address.zipcode}',
    },
];

// Application statuses mapping used to get config
export const applicationStatusTypes = {
    approved: 'success',
    declined: 'error',
    denied: 'error',
    expired: 'error',
    in_progress: 'pending',
    pended: 'pending',
    submitted: 'success',
    received: 'success',
};

// Application statuses from API
export enum applicationsStatuses {
    in_progress = 'in_progress',
    submitted = 'submitted',
    shared = 'shared',
    submitted_pending_review = 'submitted_pending_review',
}

export const selectedPlanSectionType = 'planDisplay';

export enum accordionHeaderIcons {
    chevronDown = 'chevron_down',
    chevronUp = 'chevron_up',
}
export const defaultContinueStatuses: Array<applicationsStatuses> = [applicationsStatuses.in_progress, applicationsStatuses.shared];

export class shareLiveApplicationRequest {
    send_to_email: string;
    custom_message: string;
}

export enum ApplicationNames {
    brokerPortal = 'broker-portal',
    brokerPortalMedicare = 'broker-portal-medicare',
}
