import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import 'rxjs';

@Injectable()
export class SessionTimeoutInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                event => {},
                err => {
                    if (err instanceof HttpErrorResponse && (err.status === 403 || err.status === 401)) {
                        if (err.error && err.error.error === 'user_unauthenticated') {
                            window.location.assign('/logout');
                        }
                        if (err.error && err.error.errors) {
                            err.error.errors.forEach(error => {
                                if (error.user_error && error.user_error === 'user_unauthenticated') {
                                    window.location.assign('/logout');
                                }
                            });
                        }
                    }
                }
            )
        );
    }
}

export const SESSION_TIMEOUT_PROVIDER: any = {
    provide: HTTP_INTERCEPTORS,
    useClass: SessionTimeoutInterceptor,
    multi: true,
};
