export const environment = {
    app: 'zshop',
    configs: {
        indexRoute: 'shopping',
        defaultRoute: 'applications',
        useLocalConfig: useLocalConfig,
    },
    production: true,
    payment: {
        ProcessingRegionCode: 5,
        fiserve_endpoint: 'https://billerwebui.fiservapps.com/',
    },
};
