import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getValue, stringBuilder } from '@zipari/web-utils';
import { combineLatest } from 'rxjs';
import 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiListResponse } from '../models/shared/ApiListResponse.model';
import Member from '../models/shared/Member.model';
import { MemberPolicy } from '../models/shared/MemberPolicy';
import Provider from '../models/shared/Provider.model';

import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root',
})
export class MemberService {
    private memberId;
    private claims: Array<any> = [];
    private _pcp = new BehaviorSubject<Provider>(null);

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private authService: AuthService,
        private loggerService: LoggerService
    ) {
        this.memberId = this.authService.loggedInUser.app_user_data.member_id;
        if (this.memberId) {
            this.refreshMemberData();
        }
    }

    private _memberPolicy = new BehaviorSubject<MemberPolicy>(null);

    public get memberPolicy(): Observable<MemberPolicy> {
        return this._memberPolicy.asObservable();
    }

    private _memberDetails = new BehaviorSubject<Member>(null);

    public get memberDetails(): Observable<Member> {
        return this._memberDetails.asObservable();
    }

    public get planAndMember(): Observable<any> {
        return combineLatest(this.memberPlan, this.memberDetails);
    }

    public get memberPlan(): Observable<any> {
        return this._memberPolicy.pipe(
            map(policy => {
                return getValue(policy, 'policies.0') || {};
            })
        );
    }

    public get primaryCareProvider(): Observable<Provider> {
        return this._pcp.asObservable();
    }

    // Gets an observable of all members based on member coverage data
    public get allMemberDetails() {
        return this._memberPolicy.pipe(
            map(memberData => {
                const allMembers = getValue(memberData, 'policies.0.product_coverages.0.member_coverages');
                return allMembers ? allMembers.map(member => getValue(member, 'member')) : [];
            })
        );
    }

    public get memberPolicyCoverages() {
        return this._memberPolicy.asObservable().pipe(
            map(policy => {
                const coverages = getValue(policy, 'policies.0.product_coverages');
                return coverages.length ? coverages : [];
            })
        );
    }

    public refreshMemberData() {
        this.initPolicyData();
        this.initMemberData();
        this.initPCP();
    }

    public getProviderSearchUrl(baseRoute) {
        return this._memberPolicy.asObservable().pipe(
            map(policy => {
                return (
                    `${baseRoute}?hios_id=${getValue(policy, 'policies.0.product_coverages.0.plan_information.hios_id')}` +
                    `&network=${getValue(policy, 'policies.0.product_coverages.0.plan_information.network_id')}`
                );
            })
        );
    }

    public getMemberDetails(): Observable<any> {
        const config = this.configService.getPageConfig<any>('global');
        const url = stringBuilder(config.memberApi, { member_id: this.memberId });
        return this.http.get(url);
    }

    public getPolicyData(): Observable<MemberPolicy> {
        const config = this.configService.getPageConfig<any>('global');
        const url = stringBuilder(config.memberPoliciesApi, { member_id: this.memberId });
        return this.http.get<MemberPolicy>(url);
    }

    public getPCP(): Observable<Provider> {
        const url = `api/enrollment/members/${this.memberId}/pcp/`;
        return this.http.get<Provider>(url);
    }

    public getMemberPlanSummary(): Observable<ApiListResponse<any>> {
        const config = this.configService.getPageConfig<any>('benefits');
        const url = stringBuilder(config.api.endpoint, { member_id: this.memberId });
        return this.http.get<ApiListResponse<any>>(url);
    }

    public getMemberBenefits(params?): Observable<ApiListResponse<any>> {
        const config = this.configService.getPageConfig<any>('account');
        const url = stringBuilder(config.api.getBenefitsEndpoint, { member_id: this.memberId });
        return this.http.get<ApiListResponse<any>>(url, { params });
    }

    public getMemberBenefitPeriods(params?): Observable<ApiListResponse<any>> {
        const config = this.configService.getPageConfig<any>('account');
        const url = stringBuilder(config.api.getBenefitPeriodsEndpoint, { member_id: this.memberId });
        return this.http.get<ApiListResponse<any>>(url, { params });
    }

    public getMemberClaims(): Observable<Array<any>> {
        const config = this.configService.getPageConfig<any>('claims');
        return this.claims.length
            ? of(this.claims)
            : this.http.get<ApiListResponse<any>>(config.endpoint).pipe(
                  map(response => response.results),
                  tap(results => {
                      this.claims = results;
                  })
              );
    }

    public getClaimDetails(claimId): Observable<any> {
        return this.getMemberClaims().pipe(
            map(claims => {
                return claims.find(claim => String(claim.claim_number) === String(claimId)) || {};
            })
        );
    }

    public populateMemberFilters(filters) {
        return this.allMemberDetails.pipe(
            map(memberArr => {
                // members not ready, nothing to populate filters with
                if (!memberArr.length) {
                    return false;
                } else {
                    filters.forEach(filter => {
                        // dont touch filters unless flaged to add member values
                        if (filter.memberValueKey) {
                            const mapMember = memberArr.map(member => {
                                // pull required label and value from member object
                                let optionLabel = member[filter.memberDisplayKey];
                                let optionValue = member[filter.memberValueKey];
                                // name is an object > change for display
                                if (filter.memberDisplayKey === 'name') {
                                    optionLabel = `${optionLabel.first_name} ${optionLabel.last_name}`;
                                }
                                if (filter.exactValue) {
                                    optionValue = optionLabel;
                                }
                                return { label: optionLabel, value: optionValue };
                            });
                            filter.options = [...filter.options, ...mapMember];
                        }
                    });
                    return true;
                }
            })
        );
    }

    private initPolicyData() {
        this.getPolicyData().subscribe(
            response => {
                // How are we handling multiple policies?
                const policy = getValue(response, 'results.0');
                this._memberPolicy.next(policy);
            },
            error => {
                this.loggerService.error('Failed to get member coverages', error);
            }
        );
    }

    private initMemberData() {
        this.getMemberDetails().subscribe(
            member => {
                this._memberDetails.next(member);
            },
            error => {
                this.loggerService.error('Failed to get member', error);
            }
        );
    }

    private initPCP() {
        this.getPCP().subscribe(
            pcp => {
                this._pcp.next(pcp);
            },
            error => {
                this.loggerService.error('Failed to get PCP', error);
            }
        );
    }
}
