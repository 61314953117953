import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StringBuilderPipe } from '@zipari/web-utils';

@NgModule({
    declarations: [
        // StringBuilderPipe
    ],
    imports: [CommonModule],
    exports: [
        // StringBuilderPipe
    ],
})
export class PipeModule {}
