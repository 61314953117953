<modal [config]="modalConfig" [condition]="isIdling" (cancel)="handleCancel()">
    <div class="u-flex u-column u-alignCenter" body>
        <img [class.imgShown]="!!config.imgLink" class="timeout-modal__img" [src]="config.imgLink" />
        <div class="t-data" [innerHTML]="modalBody"></div>
    </div>

    <ng-container footer>
        <div class="u-flex u-alignCenter u-justifyBetween u-maxX Timeout__footer--desktop">
            <zip-button level="medium" content="{{ btn_logout }}" (zipButtonClicked)="handleLogout()"> </zip-button>

            <zip-button level="high" content="{{ btn_continue }}" (zipButtonClicked)="handleCancel()"> </zip-button>
        </div>
        <div class="u-flex u-alignCenter u-justifyBetween u-maxX Timeout__footer--mobile">
            <zip-button
                [config]="{
                    level: 'medium',
                    size: 'fullWidth'
                }"
                content="{{ btn_logout }}"
                class="Timeout__footer--button"
                (zipButtonClicked)="handleLogout()"
            >
            </zip-button>

            <zip-button
                [config]="{
                    level: 'high',
                    size: 'fullWidth'
                }"
                content="{{ btn_continue }}"
                class="Timeout__footer--button"
                (zipButtonClicked)="handleCancel()"
            >
            </zip-button>
        </div>
    </ng-container>
</modal>
