import { DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import { TimeoutWarningModalConfig } from './shared/modules/timeout-modal/timeout-modal.constants';
import { BrowserSupportConfig } from './shared/services/browser-support.service';

export class KeepaliveErrorConfig {
    /** handles redirecting to an internal route */
    route?: string;

    /** handles redirecting to an external link */
    link?: string;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

export class IdleConfig {
    enabled: boolean = false;
    timeToIdle: number = 1200; // 20 minutes
    timeoutAfterIdle: number = 300; // 5 minutes
    interrupts: any[] = DEFAULT_INTERRUPTSOURCES;
    redirectAfterLogoutUrl?: string;
    body: string;
    title: string;
    btn_continue: string;
    btn_logout: string;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

export class KeepaliveConfig {
    endpoint: string = 'user/keep-alive/';
    enabled: boolean = true;
    interval: number = 15;
    error: KeepaliveErrorConfig;

    constructor(options: KeepaliveConfig = { interval: null, error: null, enabled: null, endpoint: null }) {
        Object.assign(this, options || {});

        this.error = new KeepaliveErrorConfig(this.error || {});
    }
}

export class TimeoutConfig {
    idle?: IdleConfig;
    keepalive?: KeepaliveConfig;
    timeoutWarningModal?: TimeoutWarningModalConfig;

    constructor(options: TimeoutConfig = { idle: null, keepalive: null }) {
        this.idle = new IdleConfig(options.idle);
        this.keepalive = new KeepaliveConfig(options.keepalive);
        this.timeoutWarningModal = new TimeoutWarningModalConfig(options.timeoutWarningModal);
    }
}

export class GlobalConfig {
    ede: boolean;
    timeout: TimeoutConfig;
    title: string;
    analytics: {
        captureGlobalClickEvents: boolean;
        [key: string]: any;
    };
    browserSupport: BrowserSupportConfig;
    contentSkip: any;
}

export enum IdleStates {
    Not_Idle = 'No longer idle',
    Started = 'Started',
    Timeout = "You've gone idle",
    TimeoutWarning = 'You will time out in ${countdown} seconds',
}
