<ng-container *ngIf="!config.customModal; else customModal">
    <confirmation-modal [config]="config" [context]="context" (confirm)="confirm.next(null)" (cancel)="cancel.next(null)">
    </confirmation-modal>
</ng-container>

<ng-template #customModal>
    <modal
        [condition]="true"
        [config]="{
            type: 'popup',
            header: {
                title: config.header
            }
        }"
        (cancel)="cancel.next(null)"
    >
        <ng-container body>
            <div class="u-flex u-column u-alignCenter custom-modal-body">
                <img *ngIf="config.customModal.imgLink" class="top-icon" [src]="config.customModal.imgLink" />

                <h6
                    *ngIf="config.customModal.header"
                    class="t-bold text-align-center custom-modal-header"
                    [innerHtml]="config.customModal.header"
                ></h6>

                <p
                    *ngIf="config.customModal.subHeader"
                    [innerHtml]="config.customModal.subHeader"
                    class="t-data text-align-center custom-modal-subheader"
                ></p>

                <zip-button
                    *ngIf="config.customModal.btn"
                    [config]="config.customModal.btn"
                    (zipButtonClicked)="onButtonClicked($event, config.customModal.btn)"
                    class="custom-modal-button"
                ></zip-button>

                <p
                    *ngIf="config.customModal.footerDescription"
                    class="t-data text-align-center"
                    [innerHtml]="config.customModal.footerDescription"
                ></p>

                <div *ngIf="config.customModal.footerOptions" class="u-flex custom-modal__footer-links">
                    <ng-container *ngFor="let link of config.customModal.footerOptions">
                        <a [href]="link.link" [innerHtml]="link.label" class="t-link custom-modal-link"> </a>
                        <icon *ngIf="link.icon" class="icon" [name]="link.icon.name" [config]="link.icon"></icon>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </modal>
</ng-template>
